import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBadge,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCol,
    MDBRow
} from 'mdb-react-ui-kit';
import dataService from '../network/dataService';
import moment from 'moment';
import DatePicker from "react-datepicker";

function HistoryModal({ show, toggleShow }) {

    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndate] = useState(new Date());




    const onClose = () => {
        // setText('')
        toggleShow(false)
    }

    useEffect(() => {
        if (!show) {
            // setText('')
        }
    }, [show])

    useEffect(() => {
        getData()
    }, [startDate, endDate])




    const getData = async () => {
        const rs = await dataService.historyCode({
            skip: 0,
            limit: 1000,
            startDate: moment(startDate).valueOf(),
            endDate: moment(endDate).valueOf()
        })
        if (!rs || rs.code) return
        setData(rs.data)
    }

    return (
        <MDBModal staticBackdrop show={show} onHidePrevented={onClose} tabIndex='-1'>
            <MDBModalDialog size='xl' >
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Lịch sử đối soát</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size='md'>
                                <p>Ngày bắt đầu</p>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            </MDBCol>
                            <MDBCol size='md'>
                                <p>Ngày bắt kết thúc</p>
                                <DatePicker selected={endDate} onChange={(date) => setEndate(date)} />
                            </MDBCol>
                        </MDBRow>
                        <div style={{ height: 40 }} />
                        <MDBTable align='middle'>
                            <MDBTableHead>
                                <tr>
                                    <th scope='col'>Tên đối tác/Voucher</th>
                                    <th scope='col'>Thời điểm đối soát</th>
                                    <th scope='col'>Trạng thái</th>
                                    <th scope='col'>Mã voucher</th>
                                    {/* <th scope='col'>Actions</th> */}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>

                                {data?.map((item, index) => <tr key={index}>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <img
                                                src={item.logoPartner}
                                                alt=''
                                                style={{ width: '45px', height: '45px' }}
                                                className='rounded-circle'
                                            />
                                            <div className='ms-3'>
                                                <p className='fw-bold mb-1'>{item.partnerName}</p>
                                                <p className='text-muted mb-0'>{item.voucherName}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p style={{ fontWeight: 'bold' }} className='fw-normal mb-1'>{moment(item.usedAt).format('HH:mm DD-MM-yyyy')}</p>

                                    </td>
                                    <td>
                                        <MDBBadge color='success' pill>
                                            Thành Công
                                        </MDBBadge>
                                    </td>
                                    <td>{item.code}</td>
                                </tr>)}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary'
                            onClick={toggleShow}
                        >
                            Đóng
                        </MDBBtn>

                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default HistoryModal
import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBSpinner,
    MDBCardImage,
    MDBCardBody,
    MDBCard,
    MDBRow,
    MDBCol
} from 'mdb-react-ui-kit';
import moment from 'moment';
import dataService from '../network/dataService';
import { useAlert } from 'react-alert';


function VoucherInfoModal({ show, toggleShow, data, onSuccess }) {

    const [loading, setLoading] = useState(false)
    const alert = useAlert()



    const onClose = () => {
        toggleShow(false)
    }



    const onSubmit = async () => {
        setLoading(true)
        const rs = await dataService.checkOutCode({
            code: data?.code
        })
        setLoading(false)
        toggleShow()
        if (!rs || rs.code) return alert.error(rs.message, {
            timeout: 9000, // custom timeout just for this one alert
        })

        alert.success('Xác thực thành công', {
            timeout: 3000, // custom timeout just for this one alert
        })

        onSuccess()



    }

    return (
        <MDBModal staticBackdrop show={show} onHidePrevented={onClose} tabIndex='-1'>
            <MDBModalDialog >
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Thông tin Voucher</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBCard>
                            <MDBCardImage src={data?.voucherInfo?.thumbnail} alt='...' position='top' />
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol style={{ textAlign: 'left' }} size={4}>
                                        <b >Tên Voucher: </b>
                                    </MDBCol>
                                    <MDBCol style={{ textAlign: 'left' }} size={8}>
                                        <b>{data?.voucherInfo?.name}</b>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol style={{ textAlign: 'left' }} size={4}>
                                        <b >Đối tác: </b>
                                    </MDBCol>
                                    <MDBCol style={{ textAlign: 'left' }} size={8}>
                                        <b>{data?.partnerInfo?.name}</b>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol style={{ textAlign: 'left' }} size={4}>
                                        <b >HSD: </b>
                                    </MDBCol>
                                    <MDBCol style={{ textAlign: 'left' }} size={8}>
                                        <b>{moment(data?.voucherInfo?.endDate).format('DD-MM-yyyy')}</b>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={toggleShow}>
                            Đóng
                        </MDBBtn>

                        <MDBBtn disabled={loading} onClick={onSubmit}  >
                            {loading ? <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> : null}
                            {loading ? 'Đang xác thực...' : "Xác thực"}
                        </MDBBtn>

                        {/* <MDBBtn onClick={onSubmit}>Xác Nhận</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default VoucherInfoModal
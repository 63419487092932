import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,

} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';

import { defineTokenRequest } from '../helpers/authHelper';
import { reactLocalStorage } from 'reactjs-localstorage';

function LogOutModal({ show, toggleShow }) {

    const [text, setText] = useState('')
    const navigate = useNavigate()


    const onClose = () => {
        setText('')
        toggleShow(false)
    }

    useEffect(() => {
        if (!show) {
            setText('')
        }
    }, [show])

    const onLogout = () => {
        localStorage.removeItem('TOKEN_APP')
        reactLocalStorage.remove('USER_INFO');
        defineTokenRequest(undefined)
        navigate('/')


    }

    return (
        <MDBModal staticBackdrop show={show} onHidePrevented={onClose} tabIndex='-1'>
            <MDBModalDialog >
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Đăng Xuất</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <h5>Bạn có chắc chắn muốn đăng xuất khỏi tài khoản này ?</h5>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={toggleShow}>
                            Huỷ
                        </MDBBtn>
                        <MDBBtn onClick={onLogout} form='formVoucher'>Đăng xuất</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default LogOutModal
import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBValidation,
    MDBValidationItem
} from 'mdb-react-ui-kit';

function CheckoutVoucher({ show, toggleShow }) {

    const [text, setText] = useState('')

    const onClose = () => {
        setText('')
        toggleShow(false)
    }

    useEffect(() => {
        if (!show) {
            setText('')
        }
    }, [show])

    const onSubmit = () => {

    }

    return (
        <MDBModal staticBackdrop show={show} onHidePrevented={onClose} tabIndex='-1'>
            <MDBModalDialog >
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Đối soát voucher</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBValidation id='formVoucher' onSubmit={onSubmit}>
                            <MDBValidationItem feedback='Vui lòng nhập mã voucher' invalid>
                                <MDBInput required value={text} onChange={(val) => setText(val.target.value)} label='Nhập mã voucher' type='text' />
                            </MDBValidationItem>
                        </MDBValidation>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={toggleShow}>
                            Đóng
                        </MDBBtn>
                        <MDBBtn form='formVoucher' type='submit'>Xác Thực</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default CheckoutVoucher
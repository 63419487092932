import axios from "axios";
import { reactLocalStorage } from 'reactjs-localstorage';


// axios.defaults.baseURL = 'https://api-htvc.mediaone.dev';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = 'https://api-htvc.mediaone.dev/';
axios.defaults.headers = {
    Authorization: "Bearer " + localStorage.getItem("TOKEN_APP")
}


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log("REQUEST", {
        baseUrl: config.baseURL,
        url: config.url,
        method: config.method,
        data: config.data || config.params,
        dataStr: JSON.stringify(config.data),
    })
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {

    console.log("RESPONSE", {
        data: response.data,
        status: response.status,
        url: response.config.url,
        body: response.config.data
    })
    return response.data;
}, function (error) {
    console.log('ERROR', error || "CANCEL REQUEST")
    if (error?.response?.status == 401 || error?.response?.status == 403) {
        localStorage.removeItem('TOKEN_APP')
        reactLocalStorage.remove('USER_INFO');
        window.location.href = '/'
    }
    console.log(error?.response)

    return error?.response?.data || 'Cancel';
});
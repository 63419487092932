import React, { useState } from 'react'
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCardBody,
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBIcon,
    MDBNavbarToggler,
    MDBNavbar,
    MDBNavbarBrand,
    MDBInputGroup,
    MDBSpinner,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
} from 'mdb-react-ui-kit';
import moment from 'moment'
import 'moment/locale/vi'
import CheckoutVoucher from '../modals/CheckoutVoucher';
import ChangePasswordModal from '../modals/ChangePasswordModal';
import HistoryModal from '../modals/HistoryModal';
import LogOutModal from '../modals/LogoutModal';
import ListHistory from '../components/ListHistory';
import VoucherInfoModal from '../modals/VoucherInfoModal';
import dataService from '../network/dataService';
import { useAlert } from 'react-alert';
import { emitPubsub } from '../helpers/pubsub';
import { reactLocalStorage } from 'reactjs-localstorage';



function Home() {

    const [showModalLogOut, setShowModalLogOut] = useState(false)
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState('')
    const [codeInfo, setCodeInfo] = useState({})
    const user = reactLocalStorage.getObject('USER_INFO');

    const alert = useAlert()


    const toggleModalLogOut = (status) => {
        if (typeof status == "boolean") {
            return setShowModalLogOut(status)
        }
        setShowModalLogOut(!showModalLogOut)
    }

    const [showModalVoucherInfo, setShowModalVoucherInfo] = useState(false)
    const toggleModalVoucherInfo = (status) => {
        if (typeof status == "boolean") {
            return setShowModalVoucherInfo(status)
        }
        setShowModalVoucherInfo(!showModalVoucherInfo)
    }

    const [showModalCheckOut, setShowModalCheckout] = useState(false)
    const toggleModalCheckout = (status) => {
        if (typeof status == "boolean") {
            return setShowModalCheckout(status)
        }
        setShowModalCheckout(!showModalCheckOut)
    }

    const [showModalChangePass, setShowModalChangePass] = useState(false)
    const toggleModalChangePass = (status) => {
        if (typeof status == "boolean") {
            return setShowModalChangePass(status)
        }
        setShowModalChangePass(!showModalChangePass)
    }

    const [showModalHistory, setShowModalHistory] = useState(false)
    const toggleModalHistory = (status) => {
        if (typeof status == "boolean") {
            return setShowModalHistory(status)
        }
        setShowModalHistory(!showModalHistory)
    }


    const checkCodeInfo = async () => {
        if (!code) return alert.info('Vui lòng nhập mã voucher', {
            timeout: 3000, // custom timeout just for this one alert

        })
        setLoading(true)
        const rs = await dataService.checkCodeInfo({
            code
        })
        setLoading(false)
        if (!rs || rs.code) return alert.info(rs.message, {
            timeout: 3000, // custom timeout just for this one alert

        })
        setCodeInfo(rs.data)
        toggleModalVoucherInfo()
    }

    const onCheckoutSuccess = () => {
        setCode('')
        setCodeInfo('')
        emitPubsub('REFRESH_HISTORY')

    }


    const getGreetingTime = (currentTime) => {
        if (!currentTime || !currentTime.isValid()) { return 'Hello'; }

        const splitAfternoon = 12; // 24hr time to split the afternoon
        const splitEvening = 17; // 24hr time to split the evening
        const currentHour = parseFloat(currentTime.format('HH'));

        if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
            // Between 12 PM and 5PM
            return 'Chào Buổi Chiều';
        } else if (currentHour >= splitEvening) {
            // Between 5PM and Midnight
            return 'Chào Buổi Tối';
        }
        // Between dawn and noon
        return 'Chào Buổi Sáng';
    }



    return (
        <div>
            <div style={{ backgroundImage: "url(/images/bghome.jpeg)", width: '100vw', height: '100vh', backgroundSize: '100%', filter: 'blur(2px)', position: 'fixed', zIndex: -1 }} />
            <MDBNavbar light style={{ backgroundColor: 'rgba(255,255,255,0.9)', position: 'fixed', zIndex: 100, width: '100vw' }}>
                <MDBContainer>
                    <MDBNavbarBrand href='#'>
                        <img
                            src='https://hyundai.thanhcong.vn/images/logo-HTV-01.svg'
                            height='30'
                            alt=''
                            loading='lazy'
                        />
                    </MDBNavbarBrand>
                    <MDBRow>
                        <MDBCol>



                            <MDBDropdown group className='shadow-0'>
                                <MDBDropdownToggle style={{ background: 'none' }} color='link' >
                                    {user?.name}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {/* <MDBDropdownItem link>Action</MDBDropdownItem> */}
                                    <MDBDropdownItem onClick={toggleModalChangePass} link>Đổi mật khẩu</MDBDropdownItem>
                                    {/* <MDBDropdownItem divider /> */}
                                    <MDBDropdownItem onClick={toggleModalLogOut} link>Đăng xuất</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                        </MDBCol>
                    </MDBRow>

                </MDBContainer>
            </MDBNavbar>
            <MDBContainer style={{ background: 'rgba(255,255,255,0.9)', minHeight: '100vh', paddingTop: 100 }}  >
                <MDBRow>
                    <MDBCol size='10'>
                        <MDBInputGroup className='mb-3'>
                            <input value={code} onChange={(val) => setCode(val.target.value)} className='form-control' type='text' placeholder="Nhập mã voucher" />
                        </MDBInputGroup>

                    </MDBCol>
                    <MDBCol size='2'>
                        <MDBBtn disabled={loading} onClick={checkCodeInfo} style={{ width: '100%' }} >
                            {loading ? <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> : null}
                            {loading ? 'Loading...' : "Kiểm tra"}
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
                <div style={{
                    height: 30
                }} />
                <MDBRow>
                    <ListHistory />
                </MDBRow>



                {/* <div style={{ height: 20 }} />
                <MDBRow>
                    <MDBCol size='md'>
                        <MDBCard style={{ cursor: 'pointer' }} onClick={toggleModalCheckout}>
                            <MDBCardBody>
                                <MDBCardTitle>Đối soát voucher</MDBCardTitle>
                                <MDBCardText>
                                    Đối soát mã voucher của khách hàng, lấy trên app hyundai me!
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard style={{ cursor: 'pointer' }} onClick={toggleModalChangePass}>
                            <MDBCardBody>
                                <MDBCardTitle>Đổi mật khẩu</MDBCardTitle>
                                <MDBCardText>
                                    {`Đổi mật khẩu đăng nhập của bạn, tăng cường bảo mật`}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard style={{ cursor: 'pointer' }} onClick={toggleModalHistory}>
                            <MDBCardBody>
                                <MDBCardTitle>Lịch sử giao dịch</MDBCardTitle>
                                <MDBCardText>
                                    Xem lịch sử giao dịch điểm, lịch sử đối soát voucher của đại lý
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <div style={{ height: 20 }} />
                <MDBRow>
                    <MDBCol size='md'>
                        <MDBCard style={{ cursor: 'pointer' }} >
                            <MDBCardBody>
                                <MDBCardTitle>Tài khoản</MDBCardTitle>
                                <MDBCardText>
                                    Xem thông tin về tài khoản đang đăng nhập
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard style={{ cursor: 'pointer' }} onClick={toggleModalLogOut}>
                            <MDBCardBody>
                                <MDBCardTitle>Đăng xuất</MDBCardTitle>
                                <MDBCardText>
                                    Đăng xuất khỏi tài khoản hiện tại
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                </MDBRow> */}


            </MDBContainer>


            {/* ///modal */}

            <CheckoutVoucher show={showModalCheckOut} toggleShow={toggleModalCheckout} />
            <ChangePasswordModal show={showModalChangePass} toggleShow={toggleModalChangePass} />
            <HistoryModal show={showModalHistory} toggleShow={toggleModalHistory} />
            <LogOutModal show={showModalLogOut} toggleShow={toggleModalLogOut} />
            <VoucherInfoModal onSuccess={onCheckoutSuccess} show={showModalVoucherInfo} toggleShow={toggleModalVoucherInfo} data={codeInfo} />

        </div>
    )
}

export default Home
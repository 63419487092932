import React, { useEffect, useState } from 'react'
import {
    MDBPagination,
    MDBBadge,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBPaginationLink,
    MDBPaginationItem,
    MDBSpinner,
    MDBIcon,
    MDBBtn

} from 'mdb-react-ui-kit';
import dataService from '../network/dataService';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { useSearchParams } from 'react-router-dom';
import { addEvent } from '../helpers/pubsub';


function ListHistory(props) {
    const LIMIT = 10
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndate] = useState(new Date());



    const [searchParams, setSearchParams] = useSearchParams();
    const skip = searchParams.get("skip") || 0
    const page = searchParams.get("page") || 0


    useEffect(() => {
        addEvent('REFRESH_HISTORY', getData)
    }, [])

    useEffect(() => {
        getData()
    }, [skip])

    useEffect(() => {
        getData()
    }, [startDate, endDate])



    const downloadFile = async () => {

        window.open(process.env.REACT_APP_BASE_URL + `/api/code/export-codes-of-store?startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}&accesstoken=${localStorage.getItem('TOKEN_APP')}`)
        // try {
        //     const response = await axios.get(
        //         // process.env.REACT_APP_BASE_URL + `api/code/export-codes-of-store?startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}`,
        //         process.env.REACT_APP_BASE_URL + `api/code/export-codes-of-store?startDate=1678353220121&endDate=1678353220121`,
        //         {
        //             responseType: 'blob',
        //             headers: {
        //                 Authorization: 'Bearer ' + localStorage.getItem('TOKEN_APP'),
        //             },
        //         },
        //     );
        //     const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //     saveAs(blob, `${moment(startDate).format('dd/MM/yyyy')}-${moment(endDate).format('dd/MM/yyyy')} .xlsx`);
        // } catch (error) {
        //     console.log(error);
        // }
    };


    const getData = async () => {
        setLoading(true)
        const rs = await dataService.historyCode({
            skip,
            limit: LIMIT,
            startDate: moment(startDate).valueOf(),
            endDate: moment(endDate).valueOf()
        })
        setLoading(false)

        if (!rs || rs.code) return
        setData(rs.data)
        const tmpTotal = rs.total / LIMIT
        setTotal(Math.ceil(tmpTotal))


    }

    return (
        <MDBContainer>
            <h5 style={{ textAlign: 'initial', marginLeft: 20 }}>Lịch sử đối soát <MDBIcon fas icon="hourglass-start" /> </h5>
            <div style={{
                height: 20
            }} />
            <MDBRow style={{ paddingLeft: 16, alignItems: 'center' }}>
                <MDBCol size='1'>
                    <MDBIcon size='lg' icon='calendar-alt' />
                </MDBCol>
                <MDBCol size='3'>
                    <MDBRow className='wrap-date'>
                        <MDBCol>
                            <h6 className='title-date'>Ngày bắt đầu</h6>
                        </MDBCol>
                        <MDBCol className='wrap-date-pick'>
                            <DatePicker showIcon selected={startDate} onChange={(date) => setStartDate(date)} />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol size='3' >
                    <MDBRow className='wrap-date'>
                        <MDBCol>
                            <h6 className='title-date'>Ngày kết thúc</h6>
                        </MDBCol>
                        <MDBCol className='wrap-date-pick' >
                            <DatePicker selected={endDate} onChange={(date) => setEndate(date)} />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center', paddingBottom: 10 }} size='4' >
                    <MDBBtn onClick={() => {
                        downloadFile()
                    }}>
                        <MDBIcon icon="download" className="mr-1" />  {' '}Xuất báo cáo
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
            <div style={{ height: 40 }} />

            {loading ? <>
                <MDBSpinner color='primary'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </> : null}
            <MDBTable align='middle'>
                <MDBTableHead>
                    <tr>
                        <th style={{ textAlign: 'initial' }} scope='col'>Tên đối tác/Voucher</th>
                        <th scope='col'>Thời điểm đối soát</th>
                        <th scope='col'>Trạng thái</th>
                        <th scope='col'>Mã voucher</th>
                        {/* <th scope='col'>Actions</th> */}
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {data?.map((item, index) => <tr key={index}>
                        <td>
                            <div className='d-flex align-items-center'>
                                <img
                                    src={item.logoPartner}
                                    alt=''
                                    style={{ width: '45px', height: '45px' }}
                                    className='rounded-circle'
                                />
                                <div className='ms-3'>
                                    <p className='fw-bold mb-1 partner-name'>{item.partnerName}</p>
                                    <p className='text-muted mb-0'>{item.voucherName}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <p style={{ fontWeight: 'bold' }} className='fw-normal mb-1'>{moment(item.usedAt).format('HH:mm DD-MM-yyyy')}</p>

                        </td>
                        <td>
                            <MDBBadge color='success' pill>
                                Thành Công
                            </MDBBadge>
                        </td>
                        <td>{item.code}</td>
                    </tr>)}
                </MDBTableBody>
            </MDBTable>

            <MDBPagination className='mb-0' style={{ justifyContent: 'center' }} >
                {/* <MDBPaginationItem>
                    <MDBPaginationLink disabled >Trang trước</MDBPaginationLink>
                </MDBPaginationItem> */}

                {new Array(total).fill(1).map((item, index) =>
                    <MDBPaginationItem style={{ cursor: 'pointer' }} active={page == index} key={index}>
                        <MDBPaginationLink onClick={() => setSearchParams({ skip: LIMIT * index, page: index })}>{index + 1}</MDBPaginationLink>
                    </MDBPaginationItem>
                )}

                {/* <MDBPaginationItem disabled>
                    <MDBPaginationLink >Trang tiếp</MDBPaginationLink>
                </MDBPaginationItem> */}
            </MDBPagination>
        </MDBContainer>
    )
}

export default ListHistory
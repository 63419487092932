import axios from 'axios';
import '../axiosConfig.js';

let dataService = {
    getCodeInfo: (data) => {
        let url = 'api/admin/htv/get-user-agreement';
        return axios.post(url, data);
    },
    login: (data) => {
        let url = 'api/user/login-store';
        return axios.post(url, data);
    },
    checkCodeInfo: (data) => {
        let url = 'api/code/check-code';
        return axios.post(url, data);
    },
    checkOutCode: (data) => {
        let url = 'api/code/checkout-code';
        return axios.post(url, data);
    },
    historyCode: (data) => {
        let url = 'api/code/get-codes-of-store';
        return axios.post(url, data);
    },
    changePassword: (data) => {
        let url = 'api/user/change-password-store';
        return axios.post(url, data);
    },
    exportHistory: (data) => {
        let url = 'api/code/export-codes-of-store';
        return axios.get(url, { params: data });
    },

};
export default dataService;

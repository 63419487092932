import React, { useEffect, useState } from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBSpinner,
    MDBValidation,
    MDBValidationItem
}
    from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import dataService from '../network/dataService';
import { defineTokenRequest } from '../helpers/authHelper';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useAlert } from 'react-alert'



function Login() {

    const [username, setUserName] = useState(false)
    const [password, setPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const alert = useAlert()


    const navigate = useNavigate()


    useEffect(() => {
        const token = localStorage.getItem('TOKEN_APP')
        if (token) {
            defineTokenRequest(token)
            navigate("/home")
        }
    }, [])

    const onLogin = async () => {
        if (!username || !password) return
        setLoading(true)
        let rs = await dataService.login({
            username,
            password
        })
        setLoading(false)
        if (!rs || rs?.code) return alert.error(rs?.message, {
            timeout: 2000, // custom timeout just for this one alert

        })

        localStorage.setItem("TOKEN_APP", rs.data.token)
        defineTokenRequest(rs.data.token)
        reactLocalStorage.setObject('USER_INFO', rs.data.userInfo);
        navigate("/home")
        alert.success("Đăng nhập thành công", {
            timeout: 3000, // custom timeout just for this one alert

        })
    }

    return (
        <MDBContainer className="my-5" style={{ paddingTop: 100 }}>

            <MDBCard>
                <MDBRow className='g-0'>

                    <MDBCol md='6' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', padding: 20 }}>
                        <MDBCardImage style={{ borderRadius: 5, }} src='/images/bglogin.jpeg' alt="login form" className='rounded-start w-100' />
                    </MDBCol>

                    <MDBCol md='6'>
                        <MDBCardBody className='d-flex flex-column'>

                            <div className='d-flex flex-row mt-2'>
                                {/* <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }} />
                                <span className="h1 fw-bold mb-0">Logo</span> */}

                                <MDBCardImage width={300} src='/images/logo.png' alt="login form" className='rounded-start' />

                            </div>

                            <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>Đăng nhập tài khoản đại lý</h5>

                            <MDBValidation onSubmit={onLogin} id='form-login' className='row g-3'>
                                <MDBValidationItem feedback='Trường này là bắt buộc' invalid >
                                    <MDBInput required onChange={(val) => setUserName(val.target.value)} wrapperClass='mb-4' label='Tên đăng nhập' type='text' size="lg" />
                                </MDBValidationItem>
                                <MDBValidationItem feedback='Trường này là bắt buộc' invalid >
                                    <MDBInput required onChange={(val) => setPassword(val.target.value)} wrapperClass='mb-4' label='Mật khẩu' type='password' size="lg" />
                                </MDBValidationItem>
                            </MDBValidation>
                            <MDBBtn type='submit' form='form-login' disabled={loading} className="mb-4 px-5" color='dark' size='lg'>
                                {loading ? <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> : null}
                                {loading ? 'Loading...' : "Đăng nhập"}
                            </MDBBtn>


                            {/* <a className="small text-muted" href="#!">Quên mật khẩu?</a>
                            <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>Don't have an account? <a href="#!" style={{ color: '#393f81' }}>Register here</a></p>

                            <div className='d-flex flex-row justify-content-start'>
                                <a href="#!" className="small text-muted me-1">Terms of use.</a>
                                <a href="#!" className="small text-muted">Privacy policy</a>
                            </div> */}

                        </MDBCardBody>
                    </MDBCol>

                </MDBRow>
            </MDBCard>

        </MDBContainer>
    );
}

export default Login;
import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
    MDBSpinner
} from 'mdb-react-ui-kit';
import dataService from '../network/dataService';
import { useAlert } from 'react-alert';

function ChangePasswordModal({ show, toggleShow }) {

    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [rePass, setRePass] = useState('')
    const [loading, setLoading] = useState(false)


    const alert = useAlert()


    const onClose = () => {
        // setText('')
        toggleShow(false)
    }

    useEffect(() => {
        if (!show) {
            // setText('')
        }
    }, [show])

    const onSubmit = async (evt) => {
        if (newPass != rePass) {
            return
        }

        setLoading(true)
        const rs = await dataService.changePassword({
            "oldPassword": oldPass,
            "newPassword": newPass,
            "confirmPassword": rePass
        })
        setLoading(false)
        toggleShow(false)
        if (!rs || rs?.code) return alert.error(rs?.message, {
            timeout: 9000, // custom timeout just for this one alert

        })
        alert.success("Đổi mật khẩu thành công", {
            timeout: 3000, // custom timeout just for this one alert

        })


    }


    return (
        <MDBModal staticBackdrop show={show} onHidePrevented={onClose} tabIndex='-1'>
            <MDBModalDialog >
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Đổi mật khẩu</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBValidation onSubmit={onSubmit} id='form2' className='row g-3'>
                            <MDBValidationItem feedback='Trường này là bắt buộc' invalid>
                                <MDBInput required value={oldPass} onChange={(val) => setOldPass(val.target.value)} label='Mật khẩu cũ' type='password' />
                            </MDBValidationItem>
                            <div style={{ height: 10 }} />
                            <MDBValidationItem feedback='Trường này là bắt buộc' invalid>
                                <MDBInput required value={newPass} onChange={(val) => setNewPass(val.target.value)} label='Mật khẩu mới' type='password' />
                            </MDBValidationItem>

                            <div style={{ height: 10 }} />
                            <MDBValidationItem feedback={rePass ? 'Mật khẩu không trùng nhau' : 'Trường này là bắt buộc'} invalid={rePass == newPass}>
                                <MDBInput required value={rePass} onChange={(val) => setRePass(val.target.value)} label='Nhập lại mật khẩu mới' type='password' />
                            </MDBValidationItem>
                        </MDBValidation>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary'
                            onClick={toggleShow}
                        >
                            Đóng
                        </MDBBtn>
                        <MDBBtn form='form2' type='submit' >
                            {loading ? <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> : null}
                            {loading ? 'Loading...' : "Xác nhận"}
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default ChangePasswordModal
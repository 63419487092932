import logo from './logo.svg';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import { Routes, Route } from "react-router-dom"
import Login from './pages/Login';
import Home from './pages/Home';
import { useEffect } from 'react';
import { defineTokenRequest } from './helpers/authHelper';


function App() {
  useEffect(() => {
    const token = localStorage.getItem('TOKEN_APP')
    if (token) {
      defineTokenRequest(token)
    }
  }, [])
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
